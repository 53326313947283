import React from 'react';
import Layout from '../layouts/layout';
import ExternalRedirect from '../components/ExternalRedirect';

const events = [
	{
		title: 'Live Music - Johnny Deadly Trio',
		href: '',
		displayDate: 'January 18 from 6:30-9:30pm',
		endTime: 1579399200000
	},
	{
		title: 'Rib Class - SOLD OUT',
		href: 'https://www.facebook.com/events/511099132831518/',
		displayDate: 'January 25 from 10am-1pm',
		endTime: 1579975200000
	},
	{
		title: 'Valentine Dinner',
		href: '',
		displayDate: 'February 14 from 4-10pm',
		description: (
			<div>
				$50 special includes
				<ul>
					<li style={{ listStyle: 'circle inside' }}>
						(2) smoked, juicy 12oz prime rib plates with a loaded
						baked potato, a side dish of your choice, and a sweet
						cornbread muffin
					</li>
					<li style={{ listStyle: 'circle inside' }}>
						bottomless non-alcoholic drinks
					</li>
					<li style={{ listStyle: 'circle inside' }}>
						a dessert of your choice to share
					</li>
				</ul>
			</div>
		),
		endTime: 1581724800000
	},
	{
		title: 'Live Music',
		href: '',
		displayDate: 'February 22nd',
		description: '',
		endTime: 1582329600000
	},
	{
		title: 'Rib Class',
		description:
			'Learn the secrets behind When Pigs Fly BBQ award winning Smoked Pork Spare Ribs.  Learn to trim, season, smoke, slice and serve like a seasoned BBQ Pit Master.  Tickets now available for this hands on class.  Limited number of seats.  All ribs, rubs, sauces, knives and smoker space will be provided.  Lunch will also be provided.  All participants will receive a certificate of completion certifying each as an official Spare Rib Pit Master.',
		href: 'https://www.facebook.com/events/458177998152766/',
		displayDate: 'March 14',
		endTime: 1584230400000
	},
	{
		title: 'Live Music',
		href: '',
		displayDate: 'March 21',
		description: '',
		endTime: 1584748800000
	}
];

function EventsPage() {
	let now = +new Date(),
		currentEvents = events.filter(e => e.endTime > now);

	return (
		<Layout title="Events">
			<h1>Events</h1>

			<p>
				For the latest announcements, check out our{' '}
				<ExternalRedirect href="https://www.facebook.com/whenpigsflybbqandcatering">
					Facebook page!
				</ExternalRedirect>
			</p>

			{currentEvents.length > 0 && (
				<ul>
					{currentEvents.map(e => {
						let title = (
							<span style={{ fontSize: '1.2em' }}>{e.title}</span>
						);
						return (
							<li>
								{e.href ? (
									<ExternalRedirect href={e.href}>
										{title}
									</ExternalRedirect>
								) : (
									title
								)}
								<br />
								<strong>{e.displayDate}</strong>
								{e.location && (
									<>
										<br />
										{e.location}
									</>
								)}
								{e.description && (
									<>
										<br />
										<p
											style={{
												whiteSpace: 'pre-wrap',
												paddingLeft: '50px'
											}}
										>
											{e.description}
										</p>
									</>
								)}
							</li>
						);
					})}
				</ul>
			)}
		</Layout>
	);
}

export default EventsPage;
